import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Event } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  ColorStrip,
  useContentSlice, useEntity, useLang,
  SliceSectionColorBlock, EventSessionTabs,
  SessionList,
  SliceLayout,
  ActiveEntityProvider,
  PAGE_EVENTS,
  useReady,
  ComponentPropsProvider,
} from '@shapeable/ui';

import { EntityTitleHeader } from '@shapeable/web';

import { 
  eventDateRange,
  formatDate,
  formatDateString,
} from '@shapeable/utils';

import { classNames } from '@shapeable/utils';
import { BANNER_EVENT, COLOR_LIGHT_BROWN, GREEN, LIGHT_BIEGE, ORANGE, VERY_LIGHT_BROWN } from '../../theme';
const cls = classNames('event-layout');
import { uniq, filter, find, get } from 'lodash';
import { SliceLayoutColorStrip } from '../slice-layouts';

// -------- Types -------->

export type EventLayoutProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventLayoutDefaultProps: Omit<EventLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
  tablet: css`
  `
});

const ColorStripStyles = breakpoints({
  base: css`

  `,
});

const BodyStyles = breakpoints({
  base: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
    position: relative;
    width: 100%;
    padding-top: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(6)};
    background-color: ${theme.COLOR(LIGHT_BIEGE)};
    background: linear-gradient(to bottom, ${theme.COLOR(LIGHT_BIEGE)}FF 0%, ${theme.COLOR(LIGHT_BIEGE)}FF 75%, ${theme.COLOR(LIGHT_BIEGE)}00 100%);
    overflow: hidden;
  `,
});

const AgendaStyles = breakpoints({
  base: css`
  `,
});

const StickyStyles = breakpoints({
  base: css`
    top: 64px;
    position: sticky;
    z-index: 20;
    width: 100%;
  `,
});

const SessionsStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(10)};
  `,
});

const BannerStyles = breakpoints({
  base: css`

    background-color: ${GREEN};

    h2 {
      font-size: ${theme.FONT_SIZE(48)};
      font-weight: 500;
      line-height: 1.1em;
      margin-top: ${theme.UNIT(4)};
      margin-bottom: ${theme.UNIT(4)};
    }


    img {
      object-fit: contain;
    }
  `,
  tablet: css`
    max-height: 600px;

    h2 {
      margin-bottom: ${theme.UNIT(6)};
      font-size: ${theme.FONT_SIZE(80)};
    }
  `
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  ColorStrip: styled(ColorStrip)`${ColorStripStyles}`,

  Banner: styled(SliceSectionColorBlock)`${BannerStyles}`,
  
  Tabs: styled(EventSessionTabs)`${TabsStyles}`,

  Body: styled.div`${BodyStyles}`,

  Agenda: styled.div`${AgendaStyles}`,
  Sticky: styled.div`${StickyStyles}`,
  Sessions: styled(SessionList)`${SessionsStyles}`,
};

export const EventLayout: React.FC<EventLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const t = useLang();
    
  const {
    banner, description, startDate, endDate, 
    images, links, color,
  } = entity;

  const bodyRef = React.useRef<HTMLDivElement>();

  const sessions = filter(entity.sessions || [], session => !!session.startDate);

  const days = uniq(sessions.map(session => formatDateString(session.startDate, 'YYYY-MM-DD')));

  const today = formatDate(new Date(), 'YYYY-MM-DD');

  const isReady = useReady();

  const defaultActiveDay = (isReady && find(days, day => day === today)) || (days.length && days[0]);

  const [ activeDay, setActiveDay ] = React.useState<string>(defaultActiveDay);

  const activeSessions = filter(sessions, session => (formatDateString(session.startDate, 'YYYY-MM-DD') === activeDay));

  const subtitle = eventDateRange(startDate, endDate);

  const bannerSlice = useContentSlice({
    title: t('Welcome'),
    intro: description,
    backgroundImages: filter([banner]),
    backgroundColor: color || COLOR_LIGHT_BROWN,
    images,
    links,
  });

  const tabOnClick = (day: string) => {
    setActiveDay(day); 
  };
  
  return (
    <ComponentPropsProvider value={{
      SessionLayout: {
        showInMinutes: true,
        expandDescription: false,
      }
    }}>
    <ActiveEntityProvider value={PAGE_EVENTS}>
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader backgroundImage={BANNER_EVENT} entity={entity} />
      <SliceLayoutColorStrip />

      <My.Banner
        entity={bannerSlice}
        imageBackgroundColor={`${get(color, 'value' || '#AA9B8C')}DD`}
      />

      <My.Body ref={bodyRef}>
        <My.Agenda id="agenda" style={{ backgroundColor: LIGHT_BIEGE }}>
          <SliceLayout boundary='content-bleed'>
          <My.Sticky>
            <My.Tabs
              entity={entity} 
              activeColor={ORANGE}
              activeDay={activeDay}
              lineColor={VERY_LIGHT_BROWN}
              lineWidth={2}
              lineVariant="dotted"
              onDateChange={tabOnClick}
            />
          </My.Sticky>

          <My.Sessions groupByTimeOfDay items={activeSessions} />
          </SliceLayout>
        </My.Agenda>

      </My.Body>

    </My.Container>
    </ActiveEntityProvider>
    </ComponentPropsProvider>
  )
};

EventLayout.defaultProps = EventLayoutDefaultProps;